import '../App.css';
import event_616_night_market from '../img/event-626-night-market.jpg';
import event_nightingale from '../img/event-nightingale.mp4';
import event_nightingale_default from '../img/event-nightingale-poster.png';
import event_513 from '../img/event-513.png';
import event_guess from '../img/event-guess.jpeg';
import event_houston from '../img/event-houston.mp4';
import event_houston_default from '../img/event-houston-default.png';
import event_catchone from '../img/event-catchone.jpeg';
import event_metaverse from '../img/event-metaverse.png';
import { Container } from 'react-bootstrap';

function Events() {

    const events = [
        {
            "id": 1,
            "name": "Nightingale",
            "location": "Hollywood, CA",
            "video": event_nightingale,
            "default": event_nightingale_default
        }, 
        {
            "id": 2,
            "name": "626 Night Market Mini",
            "location": "Santa Monica, CA",
            "image": event_616_night_market
        },
        {
            "id": 3,
            "name": "The 513",
            "location": "North Hollywood, CA",
            "image": event_513
        },
        {
            "id": 4,
            "name": "GUESS",
            "location": "Beverly Hills, CA",
            "image": event_guess
        },
        {
            "id": 5,
            "name": "Catch One",
            "location": "Los Angeles, CA",
            "image": event_catchone
        },
        {
            "id": 7,
            "name": "Metaverse Event",
            "location": "",
            "image": event_metaverse,
            "link": "https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/43628769275221891294058968329329518166989998770451034682004173243251244924929?fbclid=IwAR2_J49UbhGVKPlKIMByN0cNw8gprvbE372zwFcmNGxbTiXyJBNsxOHEoW4"
        },
        {
            "id": 6,
            "name": "Graduation Event",
            "location": "Houston, TX",
            "video": event_houston,
            "default": event_houston_default
        }
    ]

    return (
        <Container className="events">
            <div className="d-flex flex-wrap justify-content-center">
                {events.map(event => {
                    return (
                        <div className={`event ${event.video ? 'video' : ''}`} key={event.id}>
                            {event.image &&
                                (event.link ? 
                                <a href={event.link} target="_blank">
                                    <img src={event.image} alt={event.name} />
                                </a>
                                : <img src={event.image} alt={event.name} />)
                            }
                            {event.video && 
                                <video controls="controls" src={event.video} poster={event.default}>
                                    Your browser does not support the HTML5 Video element.
                                </video>
                            }
                            <p className={`mb-0 ${event.video ? '' : 'mt-1'} text-center`}>{event.name}</p>
                            <p className="text-center">{event.location}</p>
                        </div>
                    )
                })}
            </div>
        </Container>
    );
}

export default Events;
