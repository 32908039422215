import '../App.css';
import wuwulogo from '../img/wuwulogo-white.png';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import epk from '../pdf/DJ-Wuwu-EPK.pdf';
import Social from './Social';

function Header() {

  return (
    <Container>
        <Navbar className="justify-content-center navbar d-flex" expand="md" variant="dark">
            <div className="nav-item justify-content-start">
                <NavLink to="/">
                    <img
                        src={wuwulogo}
                        width="100"
                        height="100"
                        alt="wuwu logo"
                    />
                </NavLink>
            </div>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav">
                <div className="nav-item">
                    <Nav className="justify-content-center">
                        <NavLink to="/" end>Bio</NavLink>
                        <NavLink to="/events">Events</NavLink>
                        <NavLink to="/mixes">Mixes</NavLink>
                        <a href={epk} target="_blank" rel="noreferrer">EPK</a>
                        <NavLink to="/contact">Contact</NavLink>
                    </Nav>
                </div>

                <Social />

            </Navbar.Collapse>
        </Navbar>
    </Container>
  );
}

export default Header;
