import './App.css';

import Header from './components/Header';
import Bio from './components/Bio';
import Events from './components/Events';
import Mixes from './components/Mixes';
import Social from './components/Social';
import Contact from './components/Contact';

import { Routes, Route, BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Bio />} />
          <Route path="/events" element={<Events />} />
          <Route path="/mixes" element={<Mixes />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>

        <div className="footer">
          <Social />
        </div>
      </Router>
    </div>
  );
}

export default App;
