import '../App.css';
import bio from '../img/bio.png';
import { Container } from 'react-bootstrap';

function Bio() {
    return (
        <Container className="bio">
            <img
                src={bio}
                alt="DJ Wuwu"
            />
            <div className="desc">
              <p>Hello, I'm DJ Wuwu, a Los Angeles-based DJ. After spending a summer in Taiwan and immersing myself in the electronic music scene, I was inspired to learn how to DJ. I enrolled in courses at mmmmmaven DJ school in Cambridge, MA, where I learned the basics of mixing. After moving back to LA in 2015, I continued to develop my skills at Scratch DJ Academy and started performing at various events around the city, from bars and clubs to birthdays, mixers, charity events, and fitness parties. I've also had the pleasure of playing at multicultural and graduate school networking events, which has helped me to cultivate a diverse range of sounds and styles that appeal to all kinds of audiences. Whether you're looking to dance the night away or just chill and enjoy the music, I've got you covered! So come join me on the dance floor and let's make some memories together!</p>
            </div>
        </Container>
    );
}

export default Bio;
