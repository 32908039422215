import '../App.css';
import { Container, Row, Col } from 'react-bootstrap';

function Mixes() {
    return (
        <Container className="mixes">
            <Row>
                <Col md={6}>
                    <iframe width="100%" height="120" src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&light=1&feed=%2Fdjwuwu%2Fhiphoprnbtop-40-demo-mix%2F" frameborder="0" title="Hip Hop/RnB/Top 40 Demo Mix"></iframe>
                </Col>

                <Col md={6}>
                    <iframe width="100%" height="120" src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&light=1&feed=%2Fdjwuwu%2Fk-pop-demo-mix%2F" frameborder="0" title="K-Pop Demo Mix"></iframe>
                </Col>
            </Row>

            <Row>
                <Col md={6}>
                    <iframe width="100%" height="120" src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&light=1&feed=%2Fdjwuwu%2Fjuly-2020%2F" frameborder="0" title="July 2020"></iframe>
                </Col>
                <Col md={6}>
                    <iframe width="100%" height="120" src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&light=1&feed=%2Fdjwuwu%2Faugust-2020%2F" frameborder="0" title="August 2020"></iframe>
                </Col>
            </Row>
        </Container>
    );
}

export default Mixes;
