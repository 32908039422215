import '../App.css';
import { Container, Row, Col, Form } from 'react-bootstrap';
import emailjs from '@emailjs/browser';
import { useRef, useState } from 'react';

function Contact() {
    const form = useRef();
    const [formMsg, setFormMsg] = useState("");

    const sendEmail = (e) => {
        e.preventDefault();

        setFormMsg("")

        let required = ['from_name', 'from_email', 'phone', 'subject', 'message']
        for (const field of required) {
            if (!form.current[field].value) {
                setFormMsg("Please fill out all required fields");
                return
            }
        }

        if (!/\S+@\S+\.\S+/.test(form.current['from_email'].value)) {
            setFormMsg("Email address is invalid")
            return
        }

        if (!/^[0-9.-]{10}$/.test(form.current['phone'].value)) {
            setFormMsg("Phone number is invalid")
            return
        }
        
        emailjs.sendForm('service_tfy836e', 'template_1zlekfp', form.current, '7pbhhWsbmCqqoZtSw')
            .then((result) => {
                console.log(result.text);
                setFormMsg("Form successfully submitted");
                form.current.reset();
            }, (error) => {
                console.log(error.text);
                setFormMsg("Error in form submission");
            });
    };
    
    return (
        <Container className="contact">
            <p>For serious inquiries, please complete the form below or contact me at <a href="mailto:info@djwuwu.com">info@djwuwu.com</a>. Looking forward to hear more about your event!</p>

            <Form ref={form} onSubmit={sendEmail} noValidate>
                <Row>
                    <Col sm={12} md={4}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Name <span className="required">*</span></Form.Label>
                            <Form.Control type="text" required name="from_name" />
                        </Form.Group>
                    </Col>

                    <Col sm={12} md={4}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email Address <span className="required">*</span></Form.Label>
                            <Form.Control type="text" required name="from_email" />
                        </Form.Group>
                    </Col>

                    <Col sm={12} md={4}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Phone Number <span className="required">*</span></Form.Label>
                            <Form.Control type="tel" required name="phone" />
                        </Form.Group>
                    </Col>
                </Row>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Subject <span className="required">*</span></Form.Label>
                    <Form.Control type="text" required name="subject" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Message <span className="required">*</span></Form.Label>
                    <Form.Control as="textarea" required name="message" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>How Did You Hear About DJ Wuwu?</Form.Label>
                    <Form.Control as="textarea" name="how_did_you_hear" />
                </Form.Group>

                {formMsg ?
                    <p>{formMsg}</p>
                    : ''
                }

                <button type="submit" className="btn btn-primary">
                    Submit
                </button>
            </Form>
        </Container>
    );
}

export default Contact;