import '../App.css';

function Social() {

  return (

        <div className="social-icons nav-item d-sm-flex justify-content-center">
            <ul className="navbar-nav flex-row justify-content-center">
                <li>
                    <a href="https://www.youtube.com/@djwuwu/" className="nav-link grow" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-youtube"></i>
                    </a>
                </li>
                <li>
                    <a href="https://www.instagram.com/dj.wuwu/" className="nav-link grow" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-instagram"></i>
                    </a>
                </li>
                <li>
                    <a href="https://soundcloud.com/djwuwu" className="nav-link grow" target="_blank" rel="noopener noreferrer"
                            >
                        <i className="fab fa-soundcloud"></i>
                    </a>
                </li>
                <li>
                    <a href="https://www.mixcloud.com/djwuwu/" className="nav-link grow" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-mixcloud"></i>
                    </a>
                </li>
                <li>
                    <a href="https://www.twitch.tv/dj_wuwu" className="nav-link grow" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-twitch"></i>
                    </a>
                </li>
                <li>
                    <a href="https://www.tiktok.com/@djwuwu" className="nav-link grow" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-tiktok"></i>
                    </a>
                </li>
                <li>
                    <a href="mailto:info@djwuwu.com" className="nav-link grow">
                        <i className="far fa-envelope"></i>
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default Social;